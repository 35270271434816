.box-padding {
    padding: 1rem;
}

.button-margin-bottom {
    margin-bottom: 1rem !important;
}

.box-display-flex {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
}

.box-height {
    height: 200px;
}

.box-cursor-pointer {
    cursor: pointer;
}

.box-display-flex-column {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 1rem;
    cursor: pointer;
    height: 200px;
}

.dialog-content {
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
}

.fullscreen-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    padding: 16px; /* Add margin */
}

.image-container {
    position: relative;
    width: 100%;
    height: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 16px; /* Add margin */
}

.fullscreen-image {
    max-width: 100%;
    max-height: 100%;
}

@media (max-width: 600px) {
    .fullscreen-overlay {
        padding: unset; /* Adjust padding for mobile */
    }

    .image-container {
        margin: 8px; /* Adjust margin for mobile */
    }

    .fullscreen-image {
        max-width: 90%;
        max-height: 90%;
    }
}
