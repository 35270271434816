.page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    position: fixed;
    height: 4rem;
    width: 100%;
    background-color: #665139;
    border-bottom: 1px solid #000000;
    z-index: 1000;
}

.logo-link {
    text-decoration: none !important;
    display: flex;
    align-items: center;
}

.logo-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: auto;
}

.logo-text {
    font-size: 20px !important;
}

.menu-item {
    color: #fff;
    text-decoration: none;
    display: block;
    cursor: pointer;
}
.menu-sub-item {
    color: #fff !important;
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
}
.arrow {
    margin-left: 0.5rem;
    transition: transform 0.3s ease;
}

.arrow.open {
    transform: rotate(180deg);
}

.menu-item-text {
    color: #fff !important;
    border-bottom: 1px solid #000000;
}
@keyframes fadeIn {
    0% {opacity: 0;}
    100% {opacity: 1;}
}

.menu-animation {
    animation: fadeIn 0.5s;
}