.fullScreenBackground {
    background-image: linear-gradient(to right, grey, grey), url('../../images/home/background.jpeg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-blend-mode: multiply;
    width: 100%;
    height: 100vh;
    position: relative;
}

.container {
    background-image: linear-gradient(to right, grey, grey), url('../../images/home/background.jpeg');
    background-repeat: no-repeat;
    background-size: contain;
    background-blend-mode: multiply;
    width: 100%;
    padding-top: 66.7%;
}

.textContainer {
    margin: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.title {
    color: white;
    font-weight: bold;
}

.quote {
    color: white;
    font-weight: bold;
    text-align: center;
}

.quoteAuthor {
    color: white;
    text-align: center;
}

.absoluteCenterLeft {
    position: absolute;
    top: 35%;
    margin-left: 4rem;
}

.absoluteCenterRight {
    position: absolute;
    top: 35%;
    right: 4rem;
    max-width: 25%;
}
