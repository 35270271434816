.contactBox {
    max-width: 600px;
    margin: auto;
    padding: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.contactForm {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    align-items: center;
}
@media (max-width: 600px) {
    .contactForm {
        width: 90%;
        margin-left: 1rem;
        margin-right: 1rem;
    }
}

.contactButton {
    margin-top: 2px;
    width: 50%;
}